<template>
  <div class="page">
    <MainHeader />
    <slot />
  </div>
</template>
<script setup lang="ts">
const profile = usePublicProfile();
const client = useClient();
const menuOpen = ref(false);
</script>
<style scoped>
.page {
  --page-width: calc(var(--grid) * 60);
  --narrow-page-width: calc(var(--grid) * 40);
}

a[href] {
  text-decoration: none;
}

.menu {
  position: relative;
}

.menu:hover .contents,
.menu.open .contents {
  display: flex;
  flex-direction: column;
}

.contents a,
.contents button:hover {
  padding: var(--half-grid);
  width: 100%;
}
.contents a:hover {
  background-color: var(--light-gray);
}

.menu .contents {
  display: none;

  background-color: var(--white);
  position: absolute;
  right: 0px;
  top: var(--grid-3);

  box-shadow: var(--shadow);
  z-index: var(--behind-header-z);
}

.block-item {
  display: grid;
  width: var(--grid-3);
  height: var(--grid-3);
  place-content: center;
}

.block-item:hover {
  background-color: var(--light-gray);
}

.avatar {
  width: calc(var(--grid) * 2);
  height: calc(var(--grid) * 2);
  background-position: center;
  background-size: cover;
  border-radius: calc(2 * var(--grid));
  border: 1px solid black;
}

header h1 {
  color: var(--brand);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -2px;
  line-height: calc(var(--grid) * 3);
}

header {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100vw;

  background-color: var(--white);

  z-index: var(--header-z);
  position: relative;
}

header nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: calc(var(--grid) / 2);
  margin-top: 0px;
}

nav > a {
  font-weight: 500;
  color: var(--dark-gray);
  height: calc(3 * var(--grid));
  line-height: calc(3 * var(--grid));

  position: relative;
  display: flex;
  align-items: center;
}

nav > a > .icon.mobile {
  display: none;
  margin: 0rem 1rem;
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 750px) {
  .logo {
    display: none;
  }

  nav > a > .icon.mobile {
    display: unset;
  }
  nav > a > span {
    display: none;
  }
}

nav a.router-link-exact-active {
  color: var(--black);
}

nav a span {
  padding: 0px calc(var(--grid) / 2);
}

nav a:first-child {
  margin-right: var(--grid);
}

nav > a.router-link-exact-active:not(:first-child):after {
  content: " ";
  position: absolute;
  bottom: 0px;
  display: block;
  z-index: 2;
  height: 2px;
  width: 100%;
  background-color: var(--brand);
}

main {
  width: var(--page-width);
  margin: auto;
}

.spacer {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
}
</style>
