<template>
  <header>
    <nav class="page-width">
      <nuxt-link to="/">
        <Logo class="logo" />
      </nuxt-link>
      <nuxt-link to="/"
        ><i-ion-home class="mobile" /><span class="text"
          >Dashboard</span
        ></nuxt-link
      >
      <nuxt-link to="/explore/courses"
        ><i-material-symbols:travel-explore-rounded class="mobile" /><span
          >Explore</span
        ></nuxt-link
      >
      <nuxt-link to="/courses/create" alt="Create course">
        <i-pajamas-plus class="mobile" size="20" /><span>Create</span>
      </nuxt-link>
      <span class="spacer" />
      <ProCTA><template #text>Go Pro</template></ProCTA>
      <div
        class="menu block-item"
        :class="{ open: menuOpen }"
        @click="menuOpen = menuOpen ? false : true"
      >
        <Avatar v-if="profile" :user="profile" />
        <div class="contents">
          <nuxt-link to="/account">Account</nuxt-link>
          <a href="#" @click.stop.prevent="client.auth.signOut()">Log Out</a>
        </div>
      </div>
    </nav>
  </header>
</template>
<script setup lang="ts">
const profile = usePublicProfile();
const client = useClient();
const menuOpen = ref(false);
</script>
<style scoped>
header {
  --header-height: calc(var(--grid) * 3);
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100vw;

  background-color: var(--white);

  z-index: var(--header-z);
  position: relative;
}

a[href] {
  text-decoration: none;
}

.menu {
  position: relative;
}

.menu:hover .contents,
.menu.open .contents {
  display: flex;
  flex-direction: column;
}

.contents a,
.contents button:hover {
  padding: var(--half-grid);
  width: 100%;
}

.contents a:hover {
  background-color: var(--light-gray);
}

.menu .contents {
  display: none;

  background-color: var(--white);
  position: absolute;
  right: 0px;
  top: var(--header-height);

  box-shadow: var(--shadow);
  z-index: var(--behind-header-z);
}

.block-item {
  display: grid;
  width: var(--header-height);
  height: var(--header-height);
  place-content: center;
}

.block-item:hover {
  background-color: var(--light-gray);
}

.avatar {
  width: calc(var(--header-height) - var(--half-grid));
  height: calc(var(--header-height) - var(--half-grid));
  background-position: center;
  background-size: cover;
  border-radius: calc(2 * var(--grid));
  border: 1px solid black;
}

header nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: calc(var(--grid) / 2);
  margin-top: 0px;
}

nav > a {
  font-weight: 500;
  color: var(--dark-gray);
  height: var(--header-height);
  line-height: calc(3 * var(--grid));

  position: relative;
  display: flex;
  align-items: center;
}

nav > a > svg.mobile {
  display: none;
  margin: 0rem 1rem;
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 750px) {
  .logo {
    display: none;
  }

  nav > a > svg.mobile {
    display: unset;
  }

  nav > a > span {
    display: none;
  }
}

nav a.router-link-exact-active {
  color: var(--black);
}

nav a span {
  padding: 0px calc(var(--grid) / 2);
}

nav a:first-child {
  margin-right: var(--grid);
}

nav > a.router-link-exact-active:not(:first-child):after {
  content: " ";
  position: absolute;
  bottom: 0px;
  display: block;
  z-index: 2;
  height: 2px;
  width: 100%;
  background-color: var(--brand);
}

main {
  width: var(--page-width);
  margin: auto;
}

.spacer {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
}
</style>
